import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TeachersDashboard from './pages/TeachersDashboard';
import QuestionUpload from './pages/QuestionUpload/QuestionUpload';
import LoginPage from '../src/pages/LoginPage/index';
import AssessmentPortal from './pages/AssementPortal/AssessmentPortal';
import AssessmentSubPage from '../src/pages/AssessmentSubmitPage';
import Landingpage from './pages/LandingPage';
import PageNotFound from './utils/PageNotFound';
import { useEffect } from 'react';
import { communicationlogin } from './utils/chromecommanfunction';
const logoutChannel = new BroadcastChannel('logout');
function App() {
  const logoutAllTabs = () => {
    logoutChannel.onmessage = () => {
      communicationlogin();
      logoutChannel.close();
    };
  };
  useEffect(() => {
    logoutAllTabs();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Landingpage />}></Route>
        <Route path='/login' element={<LoginPage />}></Route>
        <Route path='/assessment-portal' element={<AssessmentPortal />}></Route>
        <Route path='/assessment-result' element={<AssessmentSubPage />}></Route>
        <Route path='/teacher-dashboard' element={<TeachersDashboard />}></Route>
        <Route path='/question-upload' element={<QuestionUpload />}></Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
