import React, { useEffect, useRef, useState } from 'react';
import ExamOverview from './components/ExamOverview';
import ExamSession from './components/ExamSession';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import Colors from '../../utils/Colors';
import MUIDialog from './components/MUIDialog';
import arrayShuffle from 'array-shuffle';
import { APIClient } from '../../utils/APIClient';

function AssessmentPortal() {
  const navigate = useNavigate();
  const navigationData = useLocation();
  const { qaSet, studentData, accessToken } = navigationData.state;
  // console.log('dhbsdfhsdfhbdsfhbdsfhfdhddfhs', studentData);
  // console.log('fsgaggadhgdhbdashbsadhhasdg', qaSet);
  const studentProfileData = {
    'Student ID': studentData.student_id,
    'Student Name': `${studentData.first_name} ${studentData.last_name}`,
    Class: studentData.class,
  };
  const [qnKeys, setQnKeys] = useState();
  const [shuffledData, setShuffledData] = useState();
  const [loading, setLoading] = useState(false);
  const [sessionStart, setSessionStart] = useState(false);
  const [leaveFullScreenAlert, setLeaveFullScreenAlert] = useState(false);
  const [exitCheck, setExitCheck] = useState(false);
  const [endTestDialogOpen, setEndTestDialogOpen] = useState(false);
  const [stuDetailsDialog, setStuDetailsDialog] = useState(false);
  const [missingExam, setMissingExam] = useState(false);
  const [ID, setID] = useState('');
  // console.log(ID);

  const fullscreenRef = useRef(null);

  const requestFullScreen = () => {
    if (fullscreenRef.current) {
      if (fullscreenRef.current.requestFullscreen) {
        fullscreenRef.current.requestFullscreen();
      } else if (fullscreenRef.current.mozRequestFullScreen) {
        fullscreenRef.current.mozRequestFullScreen();
      } else if (fullscreenRef.current.webkitRequestFullscreen) {
        fullscreenRef.current.webkitRequestFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    setExitCheck(true);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }

    const questionsId = {
      questionSetID: qaSet.questionSetID,
    };

    axios
      .post(`${APIClient().API_BASE_URL}/api/getTestResult`, questionsId)
      .then((res) =>
        console.log('banarresultdshdfhfgjcxhfghfjgfjdfjfxhxcvnfjhfhnxfnxfgsfhnggfhhfhshf', res),
      );
    // const serverIP = '192.168.1.33';
    // const serverPort = 3003;
    // axios
    //   .post(`http://${serverIP}:${serverPort}/api/getTestResult`, questionsId)
    //   .then((res) =>
    //     console.log('banarresultdshdfhfgjcxhfghfjgfjdfjfxhxcvnfjhfhnxfnxfgsfhnggfhhfhshf', res),
    //   );
  };

  const storeEmptyArray = () => {
    const sentStudentAnswer = {
      questionSetID: qaSet.questionSetID,
      testAnswers: [],
      student_id: studentData.student_id,
    };
    console.log(sentStudentAnswer);
    axios
      .post(`${APIClient().API_BASE_URL}/api/storeAnswers`, sentStudentAnswer)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setID(res.data.ID);
          setLoading(false);
          setSessionStart(true);
          requestFullScreen();
        } else {
          enqueueSnackbar('Something Went Wrong', { variant: 'error', preventDuplicate: true });
        }
      })
      .catch((error) => {
        enqueueSnackbar('Server Down', { variant: 'error', preventDuplicate: true });
      });
  };

  const startExamFn = async () => {
    try {
      setLoading(true);
      console.log('hgdsghhslkgdsgsdlkghsdgsdkljghsdslkdhgsdkjg', accessToken);
      const sentQuestionID = {
        questionSetID: qaSet.questionSetID,
      };
      const response = await axios.post(
        `${APIClient().API_BASE_URL}/api/getQuestionAnswer`,
        sentQuestionID,
        {
          headers: {
            Authorization: accessToken,
          },
        },
      );
      const responseData = response.data;
      console.log(responseData);
      if (responseData.success) {
        const getQASet = responseData.data.questionSet;
        getQASet.forEach(function (question) {
          question.selectedValue = null;
        });

        setQnKeys(
          getQASet.flatMap((obj) => Object.keys(obj).filter((key) => key.startsWith('qn'))),
        );
        const shuffleArray = arrayShuffle(getQASet);
        setShuffledData(shuffleArray);
        storeEmptyArray();
      } else {
        setLoading(false);
        enqueueSnackbar(responseData.error, { variant: 'error' });
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      enqueueSnackbar('Server Down', { variant: 'error' });
    }
  };

  const beforeUnloadListener = (event) => {
    event.preventDefault();
    event.returnValue = 'You have unsaved changes. Are you sure you want to refresh?';
  };

  const confirmSubmissionFn = () => {
    const rearranged = [];
    qnKeys.forEach((key) => {
      const matchingObject = shuffledData.find((obj) => obj[key] !== undefined);
      rearranged.push(matchingObject);
    });
    const sentStudentAnswer = {
      questionSetID: qaSet.questionSetID,
      testAnswers: rearranged,
      student_id: studentData.student_id,
      ID: ID,
    };
    console.log(sentStudentAnswer);
    axios
      .post(`${APIClient().API_BASE_URL}/api/storeAnswers`, sentStudentAnswer)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          console.log(res.data.message);
          if (window.location.pathname === '/assessment-portal') {
            navigate('/assessment-result', { replace: true });
            window.removeEventListener('beforeunload', beforeUnloadListener);
          }
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar('Server Down', { variant: 'error', preventDuplicate: true });
      });
  };

  const handleFullScreenChange = () => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      setLeaveFullScreenAlert(true);
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  useEffect(() => {
    // Add event listeners when the component mounts
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);

    // Clean up event listeners when the component unmounts
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
    };
  }, []);

  return (
    <>
      <Box ref={fullscreenRef} sx={{ bgcolor: 'white' }}>
        {sessionStart ? (
          <ExamSession
            shuffledData={shuffledData}
            setShuffledData={setShuffledData}
            requestFullScreen={requestFullScreen}
            exitFullScreen={exitFullScreen}
            setEndTestDialogOpen={setEndTestDialogOpen}
            exitCheck={exitCheck}
            stuDetailsDialog={stuDetailsDialog}
            setStuDetailsDialog={setStuDetailsDialog}
            studentProfileData={studentProfileData}
            qaSet={qaSet}
            confirmSubmissionFn={confirmSubmissionFn}
          />
        ) : (
          <ExamOverview
            startExamFn={startExamFn}
            loading={loading}
            studentProfileData={studentProfileData}
            setMissingExam={setMissingExam}
            qaSet={qaSet}
            beforeUnloadListener={beforeUnloadListener}
          />
        )}
      </Box>

      <MUIDialog
        loading={loading}
        exitCheck={exitCheck}
        endTestDialogOpen={endTestDialogOpen}
        setEndTestDialogOpen={setEndTestDialogOpen}
        requestFullScreen={requestFullScreen}
        setExitCheck={setExitCheck}
        confirmSubmissionFn={confirmSubmissionFn}
        leaveFullScreenAlert={leaveFullScreenAlert}
        setLeaveFullScreenAlert={setLeaveFullScreenAlert}
        stuDetailsDialog={stuDetailsDialog}
        setStuDetailsDialog={setStuDetailsDialog}
        studentProfileData={studentProfileData}
        missingExam={missingExam}
      />
    </>
  );
}

export default AssessmentPortal;
