import axios from 'axios';
import Swal from 'sweetalert2';

export const APIClient = () => {
  return {
    // API_BASE_URL: 'http://192.168.1.30:3002',
    API_BASE_URL: 'https://api.netcomeschool.com',
    API_HEADERS: {
      headers: {
        Authorization: localStorage.getItem('accessToken'),
      },
    },
  };
};

export const RemoveAccessToken = () => localStorage.removeItem('accessToken');

export const RefreshToken = async () => {
  try {
    const SentRefreshToken = {
      refreshToken: localStorage.getItem('refreshToken'),
    };
    const getNewAccessToken = await axios.post(
      `${APIClient().API_BASE_URL}/api/refreshToken`,
      SentRefreshToken,
    );
    console.log(getNewAccessToken);
    return getNewAccessToken.data;
  } catch (error) {
    console.log('error', error);
    if (error.response && error.response.status === 401) {
      return { success: false, error: undefined };
    } else {
      return { success: false, error: 'catchError' };
    }
  }
};

export const RefreshTokenSwalAlert = (newTokensErr, navigate) => {
  Swal.fire({
    title: newTokensErr === 'catchError' ? 'Server Down' : 'Session Expired',
    text: newTokensErr === 'catchError' ? 'Try again later' : 'Login Again',
    icon: 'error',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
  }).then(() => {
    RemoveAccessToken();
    navigate('/login');
  });
};
