import React, { useState, useEffect } from 'react';
import './historystyle.css';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Colors from '../../../../utils/Colors';
import { Pagination, Typography, Box, Dialog, IconButton, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import CircleLoader from 'react-spinners/CircleLoader';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputAdornment from '@mui/material/InputAdornment';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { APIClient } from '../../../../utils/APIClient';
import Skeleton from '@mui/material/Skeleton';
const History = (props) => {
  const [historyStudentsDialogBoxOpen, setHistoryStudentsDialogBoxOpen] = useState(false);
  const [getvalueFromUser, setGetvalueFromUser] = useState();
  const [teacherViewStudentsDetails, setTeacherViewStudentsDetails] = useState([]);
  const [teacherFilterViewStudentsDetails, setTeacherFilterViewStudentsDetails] = useState();
  const [dataForTotalStudentOfTestAttend, setDataForTotalStudentOfTestAttend] = useState();
  const [histroyViewStudent, setHistroyViewStudent] = useState(true);
  console.log('search', teacherViewStudentsDetails);
  const { getData, currentPage, setCurrentPage, examDatas, loading } = props;
  console.log(getData);
  const header = ['Test Name', 'Schedule', 'Questions', 'Attendees', 'Actions'];
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  console.log(lastIndex);
  const firstIndex = lastIndex - recordsPerPage;
  console.log('history', firstIndex);
  const reversedArray = [...getData].reverse();
  console.log(reversedArray);
  const records = reversedArray.slice(firstIndex, lastIndex);
  console.log('history', records);
  const npage = Math.ceil(getData.length / recordsPerPage);

  function changeCPage(id) {
    setCurrentPage(id);
  }
  const filterDate = (val) => {
    const a = new Date(val);
    const hours = a.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedMinutes = a.getMinutes().toString().padStart(2, '0');
    const final = `${a.getDate().toString().padStart(2, '0')}-${(a.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${a
      .getFullYear()
      .toString()} ${formattedHours}:${formattedMinutes}${amOrPm}`;
    return final;
  };
  const [examDetails, setExamDetails] = useState({});
  const openHistoryDialogBox = (e) => {
    setExamDetails(e);
    const { questionSetID } = e;
    setHistoryStudentsDialogBoxOpen(true);
    const questionId = {
      questionSetID: questionSetID,
    };
    axios.post(`${APIClient().API_BASE_URL}/api/getStudentsMark`, questionId).then((response) => {
      console.log('API', response.data);
      if (response.data.success) {
        setTeacherViewStudentsDetails(response.data.message);
        setHistroyViewStudent(false);
      } else {
        setTeacherViewStudentsDetails([]);
        setHistroyViewStudent(false);
      }
      console.log('came');
    });

    // Handle the response from the backend
  };
  const histroyDialogBoxClose = () => {
    setHistoryStudentsDialogBoxOpen(false);
    setHistroyViewStudent(true);
  };
  const getuserValue = (e) => {
    setGetvalueFromUser(e);

    setTeacherFilterViewStudentsDetails(
      e === ' '
        ? teacherViewStudentsDetails
        : teacherViewStudentsDetails.filter((val) =>
            val.first_name.toLowerCase().includes(e.toLowerCase()),
          ),
    );
  };
  const handleClearInput = () => {
    setGetvalueFromUser('');
    setTeacherFilterViewStudentsDetails('');
  };

  const data = [
    {
      Name: 'Ashoksjvbfhsvsklvskdv',
      Rollno: 'BEL0009',
      Mark: 99,
      Rank: 20,
    },
    {
      Name: 'John',
      Rollno: 'BEL0010',
      Mark: 95,
      Rank: 25,
    },
    {
      Name: 'Emily',
      Rollno: 'BEL0011',
      Mark: 92,
      Rank: 30,
    },
    {
      Name: 'David',
      Rollno: 'BEL0012',
      Mark: 89,
      Rank: 35,
    },
    {
      Name: 'Sophia',
      Rollno: 'BEL0013',
      Mark: 87,
      Rank: 40,
    },
    {
      Name: 'Daniel',
      Rollno: 'BEL0014',
      Mark: 94,
      Rank: 28,
    },
    {
      Name: 'Olivia',
      Rollno: 'BEL0015',
      Mark: 91,
      Rank: 32,
    },
  ];
  useEffect(() => {
    // setTeacherViewStudentsDetails(data);
  }, []);

  return (
    <div className='resultOfHistroy'>
      {loading ? (
        <div className='Historyloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircleLoader color='#6b4696' cssOverride={{}} size={99} speedMultiplier={1} />
            Loading ...
          </Typography>
        </div>
      ) : examDatas.length !== 0 ? (
        <>
          {getData.length >= 1 ? (
            <>
              <table className='tableDivs'>
                <thead>
                  <tr>
                    {header.map((head, index) => (
                      <th
                        key={index}
                        style={{ backgroundColor: Colors.globalColorThemeColor }}
                        className={
                          index === 0
                            ? 'historyTableHeaders'
                            : index === 1
                            ? 'histroyHeadName'
                            : index === 2
                            ? 'histroyHeadNoOfQuestion'
                            : index === 3
                            ? 'histroyHeadSchedule'
                            : index === 4
                            ? 'histroyHeadAttendees'
                            : 'histroyHeadActions'
                        }
                      >
                        {head}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {records.map((row, index) => (
                    <tr key={index}>
                      <td className='historyTestNameTableData'>{row.testName}</td>
                      <td className='historyNoOfQuestionTableData'>
                        {filterDate(row.startDateTime)}
                      </td>
                      <td className='historyScheduleTimeTableData'>{row.questionsCount}</td>
                      {/* <td className='historyNoOfSTudentTableData'>{row.noofstudent}</td> */}
                      <td className='historyAttendeesTableData'>{row.attendees}</td>
                      <td className='historyTableDatas'>
                        <div className='historyEditBtn' onClick={() => openHistoryDialogBox(row)}>
                          <RemoveRedEyeIcon className='historyRedEyeIcon' />
                          <span className='historyViewEditBtn'>View</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination count={npage} onChange={(e, i) => changeCPage(i)} />
            </>
          ) : (
            <div className='noDataFound'>
              <SearchOffIcon sx={{ fontSize: '150px', color: '#6b4696' }} className='icone' />
              <span className='dataFoundLetter'>No data Found</span>
            </div>
          )}
        </>
      ) : (
        <div className='Historyloading'>
          <Typography
            variant='h6'
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 'bold',
            }}
          >
            No History ...
          </Typography>
        </div>
      )}
      <Dialog
        open={historyStudentsDialogBoxOpen}
        PaperProps={{ sx: { width: '555px', height: '470px', borderRadius: '10px' } }}
      >
        <DialogTitle
          id='scroll-dialog-title'
          style={{
            backgroundColor: Colors.globalColorThemeColor,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#ffffff',
            fontSize: '16px',
            fontWeight: 600,
          }}
        >
          Students Mark
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={histroyDialogBoxClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#ffffff',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            // backgroundColor: { xs: 'yellow', sm: 'red' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              // alignItems: 'center',
              // width: { xs: '%', sm: '100%' },
              width: '100%',
              // backgroundColor: { xs: 'blue', sm: 'red' },
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'flex-start', sm: 'center' },
            }}
          >
            <Box>
              <Typography sx={{ fontSize: '14px' }}>Exam Name </Typography>
              <Typography
                sx={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  // fontSize: '17px',
                  fontSize: '15px',
                  overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '280px',
                  whiteSpace: { xs: 'wrap', sm: 'nowrap' },
                }}
              >
                {examDetails.testName}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '14px' }}> Attendees </Typography>
              <Typography
                sx={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  // fontSize: '18px',
                  fontSize: '15px',
                }}
              >
                {' '}
                {examDetails.attendees}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: '14px' }}> Questions counts </Typography>
              <Typography
                sx={{
                  color: '#000000',
                  fontWeight: 'bolder',
                  // fontSize: '18px',
                  fontSize: '15px',
                }}
              >
                {' '}
                {examDetails.questionsCount}
              </Typography>
            </Box>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Divider
              sx={{
                color: 'blue',
                fontSize: '29px',
                borderBottomWidth: '3px',
                marginTop: '15px',
                // width: { xs: '263px', sm: '480px' },
                width: '100%',
              }}
            />
          </Box>
          <Box sx={{ width: '100%' }}>
            <TextField
              sx={{
                // width: { xs: '263px', sm: '490px' },

                marginTop: '15px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px !important',
                },
                width: '100%',
              }}
              placeholder='Search Name'
              size='small'
              value={getvalueFromUser}
              onChange={(e) => getuserValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    {getvalueFromUser && (
                      <IconButton onClick={handleClearInput}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                margin: '10px 0',
              }}
            >
              {histroyViewStudent ? (
                <Skeleton variant='rounded' width={'100%'} height={'20vh'} />
              ) : teacherViewStudentsDetails &&
                (teacherFilterViewStudentsDetails
                  ? teacherFilterViewStudentsDetails
                  : teacherViewStudentsDetails
                ).length !== 0 ? (
                (teacherFilterViewStudentsDetails
                  ? teacherFilterViewStudentsDetails
                  : teacherViewStudentsDetails
                ).map((e, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '2px solid #9ca8d3',
                      borderRadius: '10px',
                      margin: '10px 0',
                      display: 'flex',
                      padding: { xs: '5px', sm: '10px' },
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '95%',
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: '200px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: { xs: '14px', sm: '26px' },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '400',
                            color: '#808080',
                            fontSize: { xs: '14px', sm: '15px' },
                          }}
                        >
                          Name:
                        </Typography>
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '200px',
                            fontSize: { xs: '15px', sm: '16px' },
                            fontWeight: 'bold',
                          }}
                        >
                          {e.first_name}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: { xs: '5px', sm: '17px' },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '400',
                            color: '#808080',
                            fontSize: { xs: '14px', sm: '15px' },
                          }}
                        >
                          Roll No:
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '16px' } }}
                        >
                          {e.student_reg_no}
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: { xs: '20px', sm: '33px' },
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '400',
                            color: '#808080',
                            fontSize: { xs: '14px', sm: '15px' },
                          }}
                        >
                          Mark:
                        </Typography>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '16px' } }}
                        >
                          {' '}
                          {e.totalMarks}
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      variant='contained'
                      sx={{
                        width: { xs: '70px', sm: '80px' },
                        height: { xs: '30px', sm: '35px' },
                        fontSize: { xs: '12px', sm: '16px' },
                        textTransform: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '400',
                          color: '#808080',
                          fontSize: { xs: '14px', sm: '15px' },
                        }}
                      >
                        {' '}
                        Rank
                      </Typography>

                      <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '15px', sm: '16px' } }}>
                        {e.rank}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: '40px',
                  }}
                >
                  <SearchOffIcon sx={{ fontSize: '100px', color: '#6b4696' }} className='icone' />
                  <Typography style={{ fontSize: '18px', color: '#6b4696' }}>
                    No data Found
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default History;
